/**
 * 商品相关的api接口封装
 */
import BaseApi from './base';

class ShopApi extends BaseApi {
  /** 分页查询模板 */
  getShopOnlineTemplate(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.template.controller.web.ShopTemplateCustomerController/getShopOnlineTemplate',
      params,
    );
  }

  /** 根据模板id获取cdn地址 */
  getSystemTemplateById(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.template.controller.web.ShopTemplateCustomerController/getShopTemplateById',
      params,
    );
  }

  /** 获取非空政策 */
  getAvailableRuleDetail(params) {
    return this._get('/api/soa/com.youzan.i18n.settings.controller.web.RuleController/getAvailableRuleDetail', params);
  }
  /** 根据页面id查询自定义页面内容 */
  getShopTemplatePage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.template.controller.web.ShopTemplatePageCustomerController/getShopTemplatePage',
      params,
    );
  }

  /** 获取店铺授权信息 判断店铺是否有效 */
  getAuthInfoByToken(params) {
    return this._get('/api/soa/com.youzan.i18n.shop.controller.web.ShopController/getAuthInfoById', params);
  }

  /**
   * 校验店铺在指定业务中是否在灰度名单中
   */
  checkGray(params) {
    return this._get('/api/soa/com.youzan.i18n.shop.controller.web.ShopGrayController/checkGray', params);
  }

  async getCountries(params) {
    const result = await this._post('/gateway/api/getCountries', params);
    return result?.countries || [];
  }

  getTemplateData(params) {
    return this._post('/api/node/getTemplate', params);
  }
  getCdnTemplate(params) {
    return this._post('/api/node/getCdnTemplate', params);
  }

  /** 获取初始化默认主菜单 */
  getShopTemplateMenuInit() {
    return this._get(
      '/api/soa/com.youzan.i18n.template.controller.web.ShopTemplateMenuCustomerController/getShopTemplateMenuInit',
      {},
    );
  }

  /** 获取初始化默认搜索 */
  getShopTemplateMenuSearchInit() {
    return this._get(
      '/api/soa/com.youzan.i18n.template.controller.web.ShopTemplateMenuCustomerController/getShopTemplateMenuSearchInit',
      {},
    );
  }

  /** 获取所有已激活的支付网关列表 */
  getAllPaymentGateways(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.asset.customer.controller.web.PaymentCustomerController/getAllPaymentGateways',
      params,
    );
  }

  /** 获取所有已激活的支付网关列表 超哥提供 平铺 */
  getPaymentGatewaysByCategory(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.asset.customer.controller.web.PaymentCustomerController/getPaymentGatewaysByCategory',
      params,
    );
  }

  /** 收藏在心愿单的商品总数 */
  getWishCountGoods(params) {
    return this._get('/api/soa/com.youzan.i18n.cart.controller.web.WishListCustomerController/countGoods', params);
  }

  /** 分页查询心愿单 */
  searchWishListWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.cart.controller.web.WishListCustomerController/searchWishListWithPage',
      params,
    );
  }

  /** 加入心愿单 */
  addToWishList(params) {
    return this._get('/api/soa/com.youzan.i18n.cart.controller.web.WishListCustomerController/addToWishList', params);
  }

  /** 查询购物车商品状态 */
  getGoodsSkuStatus(params) {
    return this._get('/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/getGoodsSkuStatus', params);
  }

  /** 从心愿单中删除商品 */
  deleteWishGoods(params) {
    return this._get('/api/soa/com.youzan.i18n.cart.controller.web.WishListCustomerController/deleteGoods', params);
  }

  /** 查询店铺LOGO */
  getShopLogo(params) {
    return this._get('/api/soa/com.youzan.i18n.spread.controller.web.SpreadCustomerController/getShopLogo', params);
  }

  /** 查询店铺推广信息 */
  getShopSpreadBasicInfo(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.spread.controller.web.SpreadCustomerController/getShopSpreadBasicInfo',
      params,
    );
  }
  // 查询C端 结账类型
  getCheckoutSettings(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.settings.controller.web.CheckoutSettingsController/getCheckoutSettings',
      params,
    );
  }

  getCheckoutSettingsV2(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.settings.controller.web.CheckoutSettingsController/getCheckoutSettingsV2',
      params,
    );
  }
  // 获取C端快捷支付开关
  getExpressPaymentParam(params) {
    return this._get('/api/soa/com.youzan.i18n.asset.controller.web.PaymentController/getExpressPaymentParam', params);
  }

  // 获取C端服务政策 内容
  getRuleDetail(params) {
    return this._post('/api/soa/com.youzan.i18n.settings.controller.web.RuleController/getRuleDetail', params);
  }
  /** 邮件订阅 */
  subscribeEmail(params) {
    return this._post('/api/soa/com.youzan.i18n.pop.customer.web.EmailSubscribeController/subscribe', params);
  }

  // 获取c端规则
  getRuleIntro(params) {
    return this._post('/api/soa/com.youzan.i18n.settings.controller.web.RuleController/getRuleIntro', params);
  }

  // 获取店铺whatsApp 配置
  getUsageConfig(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.official.app.im.controller.web.WaMessageOnlineController/getUsageConfig',
      params,
    );
  }

  // 获取店铺博客分类
  getBlogCategorys(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.blog.controller.web.BlogCategoryOnlineController/getBlogCategorys',
      params,
    );
  }
  // 博客分页查询
  searchWithPageBlog(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/searchWithPage', params);
  }
  // 获取店铺博客内容
  getBlogById(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/getBlogById', params);
  }
  // 博客分享自增
  shareIncr(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/shareIncr', params);
  }
  // 获取热门博客
  getHotBlogs(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/getHotBlogs', params);
  }
  // 获取博客统计数据
  getBlogStatistic(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/getBlogStatistic', params);
  }
  // 点赞博客
  fansBlog(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/fansBlog', params);
  }
  // 取消点赞博客
  cancelFansBlog(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogOnlineController/cancelFansBlog', params);
  }
  // 获取博客评论
  getBlogCmts(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogCmtOnlineController/getBlogCmts', params);
  }
  // 点赞评论
  fansBlogCmt(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogCmtOnlineController/fansBlogCmt', params);
  }
  // 取消点赞评论
  cancelFansBlogCmt(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogCmtOnlineController/cancelFansBlogCmt', params);
  }
  // 发表评论
  publishBlogCmt(params) {
    return this._post('/api/soa/com.youzan.i18n.blog.controller.web.BlogCmtOnlineController/publishBlogCmt', params);
  }
  // 获取c端CM 客服配置代码
  getRobinConfig(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.official.app.im.controller.web.MultiChannelImController/getConfig',
      params,
    );
  }
  getAllPop(params) {
    return this._get('/api/node/getAllPop', params);
  }
  /**
   * 获取弹窗信息（聚合）
   * @param params
   */
  getAllPopMuster = (params) => {
    return this._requestGateway('/api/pop/getAllPopMuster', params);
  };

  // 获取配置的多币种列表
  getMultiCurrencies(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.asset.multicurrency.controller.web.MultiCurrencyController/getMultiCurrencies',
      params,
    );
  }
  // 是否开启多币种
  checkIsOpened(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.asset.multicurrency.controller.web.MultiCurrencyController/checkIsOpened',
      params,
    );
  }
  // 是否开启多币种和多语言
  getMultiCurrencyStatus(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.asset.multicurrency.controller.web.MultiCurrencyController/getMultiCurrencyStatus',
      params,
    );
  }
  getMultiCurrencyAndLanguageSetting(params) {
    return this._get('/api/node/getMultiCurrencyAndLanguageSetting', params);
  }

  getHandleByRelationIds(params) {
    return this._get('/api/soa/com.youzan.i18n.seo.controller.web.SeoController/getHandleByRelationIds', params);
  }
  // 获取通知概览
  getNotifySummary(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.message.notify.controller.web.UserNotifyOnlineController/getNotifySummary',
      params,
    );
  }
  // 已读通知
  markAsRead(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.message.notify.controller.web.UserNotifyOnlineController/markAsRead',
      params,
    );
  }
  // 一键已读
  oneKeyMarkAsRead(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.message.notify.controller.web.UserNotifyOnlineController/oneKeyMarkAsRead',
      params,
    );
  }
  // 获取通知详情
  getNotifyById(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.message.notify.controller.web.UserNotifyOnlineController/getNotifyById',
      params,
    );
  }
  // 查询通知页面
  searchNotifyWithPage(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.message.notify.controller.web.UserNotifyOnlineController/searchWithPage',
      params,
    );
  }
  // 获取line
  getLineBindInfo(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.official.app.line.controller.web.LineMessage2Controller/getBindInfo',
      params,
    );
  }
  getProviders(params) {
    return this._get('/api/soa/com.youzan.i18n.openauth.controller.web.AuthController/getProviders', params);
  }
  getConnectUrl(params) {
    return this._get('/api/soa/com.youzan.i18n.openauth.controller.web.AuthController/connect', params);
  }
  exchangeToken(params) {
    return this._get('/api/soa/com.youzan.i18n.openauth.controller.web.AuthController/exchangeToken', params);
  }
  getAuthCode(params) {
    return this._get('/api/soa/com.youzan.i18n.openauth.bogus.web.BogusAuthController/getAuthCode', params);
  }
  getTranslateText = (params) => {
    return this._requestGateway(
      '/api/getTranslateText',
      params,
    );
  }
  getAuthInfo(params) {
    return this._get('/api/soa/com.youzan.i18n.appstore.auth.controller.web.AuthController/getAuthInfo', params);
  }
}

export default new ShopApi();
